import * as z from "zod";

import {
	createPeopleAddressSchema,
	CreatePeopleAddressSchemaOptions,
	PEOPLE_ADDRESS_INITIAL_DATA,
} from "./people.address";
import {
	createPeopleEmailSchema,
	CreatePeopleEmailSchemaOptions,
} from "./people.email";
import {
	createPeopleNameSchema,
	CreatePeopleNameSchemaOptions,
	PEOPLE_NAME_INITIAL_DATA,
} from "./people.name";
import {
	createPeoplePhoneSchema,
	CreatePeoplePhoneSchemaOptions,
} from "./people.phone";

/** Options for {@link createPeopleContactSchema} */
export interface CreatePeopleContactSchemaOptions {
	/** Options for the `name` address */
	address?: CreatePeopleAddressSchemaOptions;
	/** Options for the `email` address */
	email?: CreatePeopleEmailSchemaOptions;
	/** Options for the `name` field */
	name?: CreatePeopleNameSchemaOptions;
	/** Options for the `phone` address */
	phone?: CreatePeoplePhoneSchemaOptions;
}

/**
 * Creates a validation schema for {@link PeopleContact}
 *
 * @param options to create the schema
 * @returns a validation schema for {@link PeopleContact}
 */
export function createPeopleContactSchema(
	options: CreatePeopleContactSchemaOptions = {},
) {
	return z.object({
		address: createPeopleAddressSchema(options.address),
		company: z
			.string()
			.describe(
				"The company that this person is from/has relation with/...",
			),
		email: createPeopleEmailSchema(options.email),
		name: createPeopleNameSchema(options.name),
		phone: createPeoplePhoneSchema(options.phone),
	});
}

/** Utility type (and schema) for people's contact */
export type PeopleContact = z.infer<
	ReturnType<typeof createPeopleContactSchema>
>;

/** Initial data for {@link PeopleContact} (to avoid rewriting there lines) */
export const PEOPLE_CONTACT_INITIAL_DATA = {
	address: PEOPLE_ADDRESS_INITIAL_DATA,
	company: "",
	email: "",
	name: PEOPLE_NAME_INITIAL_DATA,
	phone: "",
} as const satisfies PeopleContact;

import * as z from "zod";

import { CreatePeopleEmailSchemaOptions } from "./people.email";

/**
 * Default people phone schema
 *
 * The format is "minified" (no space) for validation
 * 	(e.g at least 10 numbers, should start with `+`, ...)
 * Use another function for display purpose
 *
 * TODO: Do validation (use of https://github.com/ruimarinho/google-libphonenumber ?)
 */
export const peoplePhoneSchema = z.string().min(3);

/** Options for {@link createPeoplePhoneSchema} */
export type CreatePeoplePhoneSchemaOptions = CreatePeopleEmailSchemaOptions;

/**
 * Creates a validation schema for {@link PeoplePhone}
 *
 * @param options to create the schema
 * @returns a validation schema for {@link PeoplePhone}
 */
export function createPeoplePhoneSchema(
	options: CreatePeoplePhoneSchemaOptions = {},
) {
	const { allowEmpty = true } = options;
	return allowEmpty ? z.literal("").or(peoplePhoneSchema) : peoplePhoneSchema;
}

/** Utility type (and schema) for people phone */
export type PeoplePhone = z.infer<ReturnType<typeof createPeoplePhoneSchema>>;

import * as z from "zod";

/** Expected criteria for a password (part of a password policy) */
export type Criteria = "hasNumber" | "hasSpecialChar" | "size";

/** @internal */
interface RegexData {
	// For minimum appearance of some chars
	min: number;
	regex: RegExp;
}
const regexCriteria = {
	hasNumber: { min: 1, regex: /\d/g },
	hasSpecialChar: { min: 1, regex: /[ !"#$%&'()*+,./:;<=>?@[\\\]^_`{|}~-]/g },
} as const satisfies Partial<Record<Criteria, RegexData>>;
function regexCheck({ min, regex }: RegexData, pwd: string) {
	return (pwd.match(regex)?.length ?? 0) < min;
}

/** Custom message for the zod validation */
export const ZOD_MESSAGES = {
	hasNumber: `Must have at least ${regexCriteria.hasNumber.min} number`,
	hasSpecialChar: `Must have at least ${regexCriteria.hasSpecialChar.min} special char`,
} as const satisfies Partial<Record<Criteria, string>>;

/** Validation schema for password with all criteria */
export const schema = z
	.string()
	.min(8)
	.refine(pwd => !regexCheck(regexCriteria.hasNumber, pwd), {
		message: ZOD_MESSAGES.hasNumber,
	})
	.refine(pwd => !regexCheck(regexCriteria.hasSpecialChar, pwd), {
		message: ZOD_MESSAGES.hasSpecialChar,
	});

// Extract from a regex issue
const extractFromRegex = (issues: readonly z.ZodIssue[], msg: string) =>
	issues.find(
		issue => issue.code === z.ZodIssueCode.custom && issue.message === msg,
	);
/** Extractors for zod issue */
export const ZOD_ISSUE_EXTRACTORS = {
	hasNumber: issues => extractFromRegex(issues, ZOD_MESSAGES.hasNumber),
	hasSpecialChar: issues =>
		extractFromRegex(issues, ZOD_MESSAGES.hasSpecialChar),
	size: issues =>
		issues.find(({ code }) => code === z.ZodIssueCode.too_small),
} satisfies Record<
	Criteria,
	(issues: readonly z.ZodIssue[]) => z.ZodIssue | undefined
>;

import { css } from "@emotion/react";

export const textSlogan = css`
	font-size: 48px;
	font-weight: 100;
	line-height: normal;
`;

export const textH1 = css`
	font-size: 22px;
	font-weight: 700;
	line-height: normal;
`;

export const textH2 = css`
	font-size: 20px;
	${textH1};
`;

export const textH3 = css`
	font-size: 18px;
	font-weight: 700;
	line-height: normal;
`;

export const textH4 = css`
	font-size: 16px;
	font-weight: 700;
	line-height: normal;
`;

export const textH5 = css`
	font-size: 14px;
	font-weight: 700;
	line-height: normal;
`;

export const textBody = css`
	font-size: 14px;
	font-weight: 400;
	line-height: 18.2px;
`;

export const textBodyBold = css`
	font-size: 14px;
	font-weight: 600;
	line-height: normal;
`;

export const textSmall = css`
	font-size: 12px;
	font-weight: 400;
	line-height: normal;
`;

export const textSmaller = css`
	font-size: 10px;
	font-weight: 400;
	line-height: normal;
`;

export const textEyebrow = css`
	font-size: 10px;
	font-weight: 700;
	line-height: normal;
`;

/**
 * Style guide for usage of h1-5 tags
 * Important: note that the HTML tag used doesn't always match the text style applied (e.g. <h2> won't necessarily use the textH2 style)
 *
 * <h1>: the page title, at the top of the UI (Plans, Clients, Réglages, etc.)
 * <h2>: the main content title (hypothèques, recettes, etc.), or a modal title
 * <h3>: a content section title (usually from a <SectionTitle> comp)
 * <h4>: a content section subtitle (usually from a <SectionSubtitle> comp)
 *
 */

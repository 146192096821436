import { extendTheme } from "@mui/joy";
import { CSSProperties } from "react";

export const theme = extendTheme({
	colorSchemes: {
		light: {
			palette: {
				blue: {
					primary: "#414F6D",
					secondary: "#CFD9ED",
				},
				grey: {
					10: "#F9FAFB",
					20: "#F2F4F7",
					30: "#D9DEE8",
					40: "#CBD2E0",
					45: "#A7AFBE",
					50: "#8293B5",
					60: "#54678D",
					80: "#303A50",
					90: "#050D26",
				},
				// Because otherwise we need to override font colors manually in most Joy components
				// (at least modals, tables)
				text: {
					primary: "#050D26",
					secondary: "#050D26",
				},
				transactional: {
					danger: "#F82020",
					dangerLight: "#FEE6E6",
					dangerTransparent: "#F9333329",
					success: "#12A721",
					successLight: "#E3F4E4",
					warning: "#DF9007",
					warningLight: "#FEF3E2",
				},
				white: {
					30: "#FFFFFF4D",
					background: "#f9f9fb",
					primary: "#FFFFFF",
				},
			},
		},
	},
	components: {
		JoyModalDialog: {
			styleOverrides: {
				root: ({ theme }) => ({
					[theme.breakpoints.down("md")]: {
						left: 16,
						maxWidth: "unset",
						right: 16,
						top: "50%",
						transform: "translateY(-50%)",
						width: "unset",
					} satisfies CSSProperties,
					[theme.breakpoints.up("md")]: {
						maxWidth: "80%",
						width: "768px",
					} satisfies CSSProperties,
				}),
			},
		},
		JoyTable: {
			styleOverrides: {
				root: () => ({
					"--TableCell-height": "44px",
					"--TableCell-paddingX": "12px",
					"--TableCell-paddingY": "4px",
				}),
			},
		},
	},
	fontFamily: {
		body: "Public Sans, sans-serif",
		display: "Public Sans, sans-serif",
	},
	fontSize: { xxs: "0.62rem" },
});

declare module "@mui/joy/styles" {
	interface FontSize {
		xxs: string;
	}
	interface Palette {
		blue: {
			primary: string;
			secondary: string;
		};
		grey: {
			10: string;
			20: string;
			30: string;
			40: string;
			45: string;
			50: string;
			60: string;
			80: string;
			90: string;
		};
		transactional: {
			danger: string;
			dangerLight: string;
			dangerTransparent: string;
			success: string;
			successLight: string;
			warning: string;
			warningLight: string;
		};
		white: {
			30: string;
			background: string;
			primary: string;
		};
	}
}

import { HttpRoute } from "@nna/core";

import {
	AuthLogin,
	AuthPasswordReset,
	AuthPasswordSet,
	AuthProfile,
	AuthRefresh,
	AuthSuccess,
} from "./dtos";

/** Entrypoint route */
const entrypoint = HttpRoute.builder("auth");

/** HTTP configuration for the Auth feature */
export const AUTH_HTTP_CONFIG = {
	routes: {
		/** Returns the information of the connected session */
		getProfile: entrypoint
			.addSegment("profile")
			.get<() => Promise<AuthProfile.Dto>>(),
		/** Logs in a user */
		login: entrypoint
			.addSegment("login")
			.post<(body: AuthLogin.Dto) => Promise<AuthSuccess.Dto>>(),
		/** Logout a user (only useful with cookies) */
		logout: entrypoint.addSegment("logout").post<() => Promise<void>>(),
		/** Start a "forget/reset-password" process */
		passwordReset: entrypoint
			.addSegment("password/reset")
			.post<(body: AuthPasswordReset.Dto) => Promise<void>>(),
		/** Set the password for an (non-authenticated) user */
		passwordSet: entrypoint
			.addSegment("password/set")
			.put<(body: AuthPasswordSet.Dto) => Promise<void>>(),
		/** Refresh an existing token */
		refresh: entrypoint
			.addSegment("refresh")
			.post<(body: AuthRefresh.Dto) => Promise<AuthSuccess.Dto>>(),
	} satisfies HttpRoute.Definitions,
} as const;

/** HTTP specification for the Auth feature */
export type AuthHttp = HttpRoute.Handlers<typeof AUTH_HTTP_CONFIG.routes>;

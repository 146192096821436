import { ReactNode } from "react";

import { Banner, BannerProps } from "./Banner";
import { ButtonIcon } from "../../ui-form/components/Button/ButtonIcon";

/** Props for {@link Toast} */
export interface ToastProps extends Pick<BannerProps, "variant"> {
	/** Same as {@link BannerProps.children} */
	message?: ReactNode;
	/**
	 * Event when the close button is clicked
	 * Does not show the button is not set
	 */
	onClose?: () => void;
	title: string;
}

export const Toast = (props: ToastProps): JSX.Element => {
	const { message, onClose, ...bannerProps } = props;

	const CloseButton = onClose && (
		<ButtonIcon name="close" noBackground onClick={onClose} />
	);

	return (
		<Banner
			data-testid="ui/toast"
			{...bannerProps}
			closeButton={CloseButton}
		>
			{message}
		</Banner>
	);
};

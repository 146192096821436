import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AppProps } from "next/app";
import Head from "next/head";
import { appWithTranslation, useTranslation } from "next-i18next";
import { useState } from "react";
import { ToastContainer } from "react-toastify";

import nextI18nextConfig from "../../next-i18next.config";
import { ThemeProvider } from "../features/ui-layout/styles/ThemeProvider";
import { ReactQueryConfig } from "../services/api/reactQueryConfig";

import "react-toastify/dist/ReactToastify.css";

import "./styles.css";

function CustomApp({
	Component,
	pageProps,
	router,
}: AppProps<Record<never, never>>) {
	const { t } = useTranslation();
	const [queryClient] = useState(() => ReactQueryConfig(router, t));

	return (
		<QueryClientProvider client={queryClient}>
			<ThemeProvider>
				<Head>
					<title>GRAL - Finaversum</title>
				</Head>

				<main className="app">
					<Component {...pageProps} />
					<ToastContainer />
				</main>
			</ThemeProvider>

			{/*
				Ignored on non-dev builds:
				https://tanstack.com/query/v4/docs/framework/react/devtools#install-and-import-the-devtools

				FIXME: something better, ignored but still included in bundled app
			*/}
			<ReactQueryDevtools />
		</QueryClientProvider>
	);
}

export default appWithTranslation(CustomApp, nextI18nextConfig);

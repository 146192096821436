import { CssVarsProvider } from "@mui/joy";
import { ReactNode } from "react";

import { theme } from "./theme";

/** Props for {@link ThemeProvider} */
export interface ThemeProviderProps {
	children: ReactNode;
}
/** Provider for the main style (mui/joy) of the application */
export function ThemeProvider(props: ThemeProviderProps) {
	return <CssVarsProvider theme={theme}>{props.children}</CssVarsProvider>;
}

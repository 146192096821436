import { Criteria, schema, ZOD_ISSUE_EXTRACTORS } from "./criteria";

/** Dict of criteria that are not respected */
export type CheckErrors = Record<Criteria, boolean>;

/**
 * check criteria for a password
 *
 * @param password to check
 * @returns dict of criteria
 */
export function check(password: string) {
	const result = schema.safeParse(password);
	const issues = result.success ? [] : result.error.errors;

	const errors: CheckErrors = {
		hasNumber: !!ZOD_ISSUE_EXTRACTORS.hasNumber(issues),
		hasSpecialChar: !!ZOD_ISSUE_EXTRACTORS.hasSpecialChar(issues),
		size: !!ZOD_ISSUE_EXTRACTORS.size(issues),
	};

	return { errors, isValid: result.success };
}

import { JwtPayload } from "jsonwebtoken";
import * as z from "zod";

/** Validation schema for {@link JwtPayloadMeta} */
export const jwtPayloadMetaSchema = z.object({
	// !! Simple number values (* 1000 for JS dates)
	exp: z.number(),
	iat: z.number(),
} satisfies Record<keyof Pick<Required<JwtPayload>, "exp" | "iat">, unknown>);

/** Base of the "meta-data" inside JWT payloads */
export type JwtPayloadMeta = z.infer<typeof jwtPayloadMetaSchema>;

/**
 * Extracts the dates from a {@link JwtPayloadMeta}, usable withing JS.
 *
 * @param payload to get the dates from
 * @returns the date (for JS)
 */
export function extractJwtMetaDateRange(payload: JwtPayloadMeta) {
	const { exp, iat } = payload;
	return {
		expireOn: new Date(exp * 1000),
		issuedAt: new Date(iat * 1000),
	};
}

import * as z from "zod";

/** Options for {@link createPeopleAddressSchema} */
export interface CreatePeopleAddressSchemaOptions {
	/**
	 * Minimal length of the fields
	 *
	 * @default 0
	 */
	min?: number;
}

/**
 * Creates a validation schema for {@link PeopleAddress}
 *
 * @param options to create the schema
 * @returns a validation schema for {@link PeopleAddress}
 */
export function createPeopleAddressSchema(
	options: CreatePeopleAddressSchemaOptions = {},
) {
	const { min = 0 } = options;
	return z.object({
		// No constraint about the relation (e.g. does the city exists in a country)
		//	Let the frontend uses 3rd party tools for better management

		// DB suggestion: store `city`, `country` and `district` in lowercase to avoid duplicates

		city: z.string().min(min).describe("City of the address"),
		country: z.string().min(min).describe("Country of the address"), // TODO: apply countryValue schema ?
		district: z
			.string()
			.min(min)
			.describe('District of the address (or "Canton" in swiss-french )'),
		/** As `string` as in case of special code (e.g. postcode CH-1233) */
		postcode: z.string().min(min).describe("The postcode of the city"),
		street: z.string().min(min).describe("Street name of the address"),
	});
}

/** Utility type (and schema) for people's addresses */
export type PeopleAddress = z.infer<
	ReturnType<typeof createPeopleAddressSchema>
>;

/** Initial data for {@link PeopleAddress} (to avoid rewriting there lines) */
export const PEOPLE_ADDRESS_INITIAL_DATA = {
	city: "",
	country: "suisse",
	district: "",
	postcode: "",
	street: "",
} as const satisfies PeopleAddress;

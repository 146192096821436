import { styled } from "@mui/joy";

import { Icon, IconNameKey } from "./Icons/Icon";
import { textBody, textBodyBold } from "../../ui-layout/styles/textStyles";
import { theme } from "../../ui-layout/styles/theme";

/** The list of available banner variants */
export enum BannerVariants {
	ERROR = "ERROR",
	INFO = "INFO",
	SUCCESS = "SUCCESS",
	WARNING = "WARNING",
}

/** Props for {@link Banner} */
export interface BannerProps {
	/** The banner content */
	children: React.ReactNode;
	/** Close button component (typically passed by Toasts) */
	closeButton?: React.ReactNode;
	/** The banner title */
	title?: string;
	/** If we are in a toast layout context, so that we adapt the UI (default: `false`) */
	toastLayout?: boolean;
	/** The banner UI variant (default: ERROR) */
	variant?: BannerVariants;
}

/**
 * Banner component
 *
 * @param props Props to generate the component
 */
export const Banner = (props: BannerProps) => {
	const {
		children,
		closeButton,
		title,
		variant = BannerVariants.ERROR,
		...rootProps
	} = props;

	return (
		<BannerContainer
			data-testid="ui/banner"
			toastLayout={false}
			variant={variant}
			{...rootProps}
		>
			<Icon
				color={styles[variant].textColor}
				name={styles[variant].icon}
				size={16}
			/>
			<Content>
				{title && <Title>{title}</Title>}
				{children}
			</Content>

			{closeButton}
		</BannerContainer>
	);
};

interface VariantStyle {
	background: string;
	icon: IconNameKey;
	textColor: string;
}

const styles = {
	[BannerVariants.ERROR]: {
		background: theme.palette.transactional.dangerLight,
		icon: "closeFilled",
		textColor: theme.palette.transactional.danger,
	},
	[BannerVariants.INFO]: {
		background: theme.palette.blue.secondary,
		icon: "information",
		textColor: theme.palette.blue.primary,
	},
	[BannerVariants.SUCCESS]: {
		background: theme.palette.transactional.successLight,
		icon: "checkmarkFilled",
		textColor: theme.palette.transactional.success,
	},
	[BannerVariants.WARNING]: {
		background: theme.palette.transactional.warningLight,
		icon: "information",
		textColor: theme.palette.transactional.warning,
	},
} as const satisfies Record<BannerVariants, VariantStyle>;

const BannerContainer = styled("div")<{
	toastLayout: boolean;
	variant: BannerVariants;
}>`
	background: ${props => styles[props.variant].background};
	border-left: 3px solid
		${({ toastLayout, variant }) =>
			toastLayout && styles[variant].textColor};
	border-radius: 4px;
	color: ${props => styles[props.variant].textColor};
	display: flex;
	gap: 8px;
	padding: 16px;
	${textBody}
`;

const Title = styled("h3")`
	${textBodyBold}
`;

const Content = styled("div")`
	display: flex;
	flex: 1;
	flex-direction: column;
	gap: 4px;
`;

import * as z from "zod";

/** Default people email schema */
export const peopleEmailSchema = z.string().email();

/** Options for {@link createPeopleEmailSchema} */
export interface CreatePeopleEmailSchemaOptions {
	/**
	 * Allow an empty string
	 *
	 * @default true
	 */
	allowEmpty?: boolean;
}

/**
 * Creates a validation schema for {@link PeopleEmail}
 *
 * @param options to create the schema
 * @returns a validation schema for {@link PeopleEmail}
 */
export function createPeopleEmailSchema(
	options: CreatePeopleEmailSchemaOptions = {},
) {
	const { allowEmpty = true } = options;
	return allowEmpty ? z.literal("").or(peopleEmailSchema) : peopleEmailSchema;
}

/** Utility type (and schema) for people email */
export type PeopleEmail = z.infer<ReturnType<typeof createPeopleEmailSchema>>;

import { OmitKnown } from "@nna/core";
import { Router } from "next/router";
import { Query, Route } from "nextjs-routes";
import { Entry } from "~/common";
import { AuthPasswordSet } from "~/common/auth";
import { PlanModel } from "~/common/plan";
import { PlanDto } from "~/common/plan/dtos";

/** Single Route meta data */
export interface RouteMeta<R extends Route = Route> {
	/** Determine, with parameters, if the current route is active */
	isActive?: (...params: never[]) => boolean;
	/** Get a {@link UrlObject} to use with the Next router */
	url: (...params: never[]) => R;
}

/** Recursive structure for route definition */
export type RouteDict = RouteMeta | { [K: string]: RouteDict };

/** Known pathnames */
export type RoutePathname = Route["pathname"];

/**
 * Creates a base {@link RouteMeta} for a given page (routePath)
 *
 * @param routePath the pathname to the page
 * @param completeUrl complete {@link RouteMeta.url} without the need to specify the pathname
 * @returns {RouteMeta} base for the given routePath
 */
function createBaseRouteMeta<
	const P extends RoutePathname,
	const URL extends (
		...params: never[]
	) => OmitKnown<Extract<Route, { pathname: P }>, "pathname">,
>(routePath: P, completeUrl: URL) {
	return {
		isActive: (route: Router["route"]) => route.startsWith(routePath),
		// @ts-expect-error -- annoying conversion from `never[]` to the parameters
		url: (...params: Parameters<URL>) => ({
			...completeUrl(...params),
			pathname: routePath,
		}),
	} as const satisfies RouteMeta<Extract<Route, { pathname: P }>>;
}

const planBasePath = "/plans" satisfies RoutePathname;

/** Params for most of Entry routes */
export interface PlanNEntryRouteParams {
	/** Param for {@link EntryDto} */
	entryId: Entry.EntryDto["_id"];
	/** Param for {@link PlanDto} */
	planId: PlanDto["_id"];
}
/** Params for most of Entry/Information/Residence routes */
export interface EntryResidenceRouteParams extends PlanNEntryRouteParams {
	/** Param for {@link Entry.ResidenceDto} */
	residenceId: Entry.ResidenceDto["_id"];
}
/** Params for most of Entry/Recipe routes */
export interface EntryRecipeRouteParams extends PlanNEntryRouteParams {
	/** Param for {@link Entry.RecipeDto} */
	recipeId: Entry.RecipeDto["_id"];
}
/** Params for most of Entry/Recipe/Salary routes */
export interface EntryRecipeSalaryRouteParams extends EntryRecipeRouteParams {
	/** Param for {@link Entry.SalaryDto} */
	salaryId: Entry.SalaryDto["_id"];
}

function transformToRouteQuery<K extends string>(
	params: Record<K, number | string>,
) {
	return Object.fromEntries(
		Object.entries<number | string>(params).map(([key, val]) => [
			key,
			val.toString(),
		]),
	) as Record<K, string>;
}

/** All known page route */
export const ROUTES = {
	auth: {
		forgotPassword: { url: () => ({ pathname: "/auth/forgot-password" }) },
		login: { url: () => ({ pathname: "/auth/login" }) },
		setPassword: { url: () => ({ pathname: AuthPasswordSet.PATH }) },
	},
	customers: {
		...createBaseRouteMeta("/customers", (query: Query = {}) => ({
			query,
		})),
		new: createBaseRouteMeta("/customers/new", (query: Query = {}) => ({
			query,
		})),
		one: createBaseRouteMeta(
			"/customers/[customerId]",
			(customerId: number | string, query: Query = {}) => ({
				query: { ...query, customerId: customerId.toString() },
			}),
		),
	},
	entry: {
		information: {
			residences: {
				new: createBaseRouteMeta(
					"/plans/[planId]/entries/[entryId]/information/residences/new",
					(params: PlanNEntryRouteParams) => ({
						query: transformToRouteQuery(params),
					}),
				),
				one: createBaseRouteMeta(
					"/plans/[planId]/entries/[entryId]/information/residences/[residenceId]",
					(params: EntryResidenceRouteParams) => ({
						query: transformToRouteQuery(params),
					}),
				),
			},
			...createBaseRouteMeta(
				"/plans/[planId]/entries/[entryId]/information",
				(params: PlanNEntryRouteParams) => ({
					query: transformToRouteQuery(params),
				}),
			),
		},
		recipes: {
			new: createBaseRouteMeta(
				"/plans/[planId]/entries/[entryId]/recipes/new",
				(params: PlanNEntryRouteParams) => ({
					query: transformToRouteQuery(params),
				}),
			),
			one: {
				salaries: {
					new: createBaseRouteMeta(
						"/plans/[planId]/entries/[entryId]/recipes/[recipeId]/salaries/new",
						(params: EntryRecipeRouteParams) => ({
							query: transformToRouteQuery(params),
						}),
					),
					one: createBaseRouteMeta(
						"/plans/[planId]/entries/[entryId]/recipes/[recipeId]/salaries/[salaryId]",
						(params: EntryRecipeSalaryRouteParams) => ({
							query: transformToRouteQuery(params),
						}),
					),
				},
				...createBaseRouteMeta(
					"/plans/[planId]/entries/[entryId]/recipes/[recipeId]",
					(params: EntryRecipeRouteParams) => ({
						query: transformToRouteQuery(params),
					}),
				),
			},
			...createBaseRouteMeta(
				"/plans/[planId]/entries/[entryId]/recipes",
				(params: PlanNEntryRouteParams) => ({
					query: transformToRouteQuery(params),
				}),
			),
		},

		...createBaseRouteMeta(
			"/plans/[planId]/entries/[entryId]",
			(params: PlanNEntryRouteParams) => ({
				query: transformToRouteQuery(params),
			}),
		),
	},
	errors: {},
	home: { url: () => ({ pathname: "/" }) },
	plans: {
		children: {
			one: {
				url: (id: PlanModel["_id"]) => ({
					pathname: `${planBasePath}/[planId]`,
					query: { planId: id.toString() },
				}),
			},
			settings: createBaseRouteMeta(
				"/plans/[planId]/settings",
				(params: Pick<PlanNEntryRouteParams, "planId">) => ({
					query: transformToRouteQuery(params),
				}),
			),
		},
		...createBaseRouteMeta(planBasePath, () => ({})),
	},
} as const satisfies Record<string, RouteDict>;

import * as z from "zod";

import * as AuthPasswordCriteria from "../password-criteria";

/** Path used for the "set-password" page */
export const PATH = "/auth/reset-password";
/** Query to set the "set-password" token on its page */
export const QUERY = "token";

/**
 * Generates the full-path of the page for the "set-password".
 *
 * @param token to inject in the full path
 * @returns the full path to concatenate with the frontend host
 */
export function generateFullPath(token: string) {
	return `${PATH}?${QUERY}=${token}`;
}

/** Validation schema for {@link Dto} */
export const schema = z.object({
	password: AuthPasswordCriteria.schema,
	token: z.string().describe('"2FA Token" when setting a password'),
});
/** DTO when a user sets its password (from forget-password or invitation) */
export type Dto = z.infer<typeof schema>;

import * as z from "zod";

/** @internal */
interface PeopleFields<T> {
	first: T;
	last: T;
}
/** @internal */
type PeopleFieldsLength = PeopleFields<number>;

/** Options for {@link createPeopleNameSchema} */
export interface CreatePeopleNameSchemaOptions {
	/**
	 * Minimal length of the fields.
	 * Either default minimum for all fields or specify each one
	 *
	 * @default 0
	 */
	min?: PeopleFieldsLength | number;
}

/**
 * Creates a validation schema for {@link PeopleName}
 *
 * @param options to create the schema
 * @returns a validation schema for {@link PeopleName}
 */
export function createPeopleNameSchema(
	options: CreatePeopleNameSchemaOptions = {},
) {
	const minimumDefault = 0;
	const { min = minimumDefault } = options;

	const mins: PeopleFieldsLength =
		typeof min === "number" ? { first: min, last: min } : min;

	return z.object({
		first: z.string().min(mins.first).describe("First name"),
		last: z.string().min(mins.last).describe("Last name (= family name)"),
	} satisfies PeopleFields<z.ZodString>);
}

/** Utility type (and schema) for people's names */
export type PeopleName = z.infer<ReturnType<typeof createPeopleNameSchema>>;

/** Initial data for {@link PeopleName} (to avoid rewriting there lines) */
export const PEOPLE_NAME_INITIAL_DATA = {
	first: "",
	last: "",
} as const satisfies PeopleName;

import { createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import { userDtoSchema } from "./user.dto";

/** Validation schema for {@link UserQueryResultsDto} */
export const userQueryResultsSchema = createQueryResultsSchema(userDtoSchema);

/** Results for {@link UserDto} from a query */
export type UserQueryResultsDto = z.infer<typeof userQueryResultsSchema>;

import * as z from "zod";

import { jwtPayloadMetaSchema } from "../../jwt.payload-meta";

/** Validation schema for {@link JwtPayload} */
export const jwtPayloadSchema = z.object({
	/** Internal token */
	token: z.string(),
	/** Internal user id */
	uId: z.number(),
});
/** Content of the JWT for the password-token */
export type JwtPayload = z.infer<typeof jwtPayloadSchema>;

/** Validation schema for {@link JwtPayloadFull} */
export const jwtPayloadFullSchema =
	jwtPayloadSchema.merge(jwtPayloadMetaSchema);
/** Extend {@link JwtPayload} with regular payload metadata */
export type JwtPayloadFull = z.infer<typeof jwtPayloadFullSchema>;
